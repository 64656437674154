<template>
  <ion-page>
    <ion-content>
      <div class="main">
        <div class="topBackground">
          <ion-text class="regular cWhite ion-text-center">
            <h1 class="ion-no-margin menuTitleFont">Postez votre annonce</h1>
          </ion-text>
        </div>
        <div class="maxWidth" v-if="isPharmacyComplete && isIBANUploaded">
          <div style="display: flex; justify-content: center;">
            <ion-button class="ion-item-center ion-margin-bottom" v-on:click="this.setIsRecurent(false)">
              Besoin ponctuel <span v-if="!this.isRecurent"> ✔️</span>
            </ion-button>
            <ion-button class="ion-item-center ion-margin-bottom" v-on:click="this.setIsRecurent(true)">
              Besoin récurrent <span v-if="this.isRecurent"> ✔️</span>
            </ion-button>
          </div>

          <div v-if="!this.isRecurent">
            <ion-text color="secondary">
            <h1 class="ion-no-padding ion-margin">Veuillez sélectionner les jours de vos commandes ponctuelles</h1>
            </ion-text>
            <Calendar
                locale='fr'
                :min-date="new Date((new Date()).getTime() + (24 * 60 * 60 * 1000))"
                is-expanded
                :attributes="attributes"
                @dayclick="onDayClick"
                style="width: 100%"
                v-show="!this.isRecurent"
            />
            <ion-card v-for="(day, index) in days" :key="day">
            <ion-card-header>
              <ion-card-title>
                <h2 style="vertical-align: baseline;">
                  {{ index + 1 }}. {{ day.ariaLabel }}
                  <ion-icon :icon="trashOutline"
                            style="color: red; cursor: pointer; height: 24px; vertical-align: baseline;"
                            @click="onDayClick(day)"
                  />
                </h2>
              </ion-card-title>
            </ion-card-header>
            <ion-card-content class="no-padding-mobile">
              <div style="display: flex; justify-content: space-around;">
                <ion-item slot="start" lines="full" class="input-wrapper display-content">
                  <ion-label class="label" position="floating">Heure de début</ion-label>
                  <ion-datetime
                      displayFormat="HH:mm"
                      locale='fr-FR'
                      minuteValues="0,15,30,45"
                      show-default-buttons="true"
                      size="cover"
                      done-text="Confirmer"
                      cancel-text="Retour"
                      v-model="day.beginTime"
                  />
                </ion-item>
                <ion-item slot="end" class="input-wrapper display-content">
                  <ion-label class="label" position="floating">Heure de fin</ion-label>
                  <ion-datetime displayFormat="HH:mm"
                                locale='fr-FR'
                                minuteValues="0,15,30,45"
                                show-default-buttons="true"
                                done-text="Confirmer"
                                cancel-text="Retour"
                                v-model="day.endTime"
                  />
                </ion-item>
              </div>
              <div style="display: flex; justify-content: space-around;">
                <ion-item slot="start" class="input-wrapper display-content">
                  <ion-label class="label" position="floating">Temps de repos</ion-label>
                  <ion-datetime displayFormat="HH:mm"
                                locale='fr-FR'
                                hourValues="0,1,2"
                                minuteValues="0,15,30,45"
                                show-default-buttons="true"
                                size="cover"
                                done-text="Confirmer"
                                cancel-text="Retour"
                                v-model="day.pauseHours"
                  />
                </ion-item>

              </div>

              <div class="checkboxes-container">
                <div class="checkbox-input" style="margin-right: 30px">
                  <ion-label position="fixed" type="dark" class="label" style="flex: auto; width: 9rem">Horaire flexible
                    ?
                  </ion-label>
                  <ion-checkbox @click="day.isFlexible = !day.isFlexible" mode="ios"></ion-checkbox>
                </div>

                <div class="checkbox-input">
                  <ion-label position="fixed" type="dark" class="label" style="flex: auto; width: 9rem">Titulaire
                    présent ?
                  </ion-label>
                  <ion-checkbox @click="day.ownerStatus = !day.ownerStatus" mode="ios"></ion-checkbox>
                </div>
              </div>
              <div>
                  <ion-item slot="start" class="input-wrapper display-content">
                  <ion-label style="margin-top: 10px;" class="label" position="floating"><h2>Ajoutez une prime journalière à l'intérimaire</h2></ion-label>
                  <ion-label style="margin-bottom: 10px;" class="label" position="floating"><h2>(permet d'obtenir plus facilement des candidats)</h2></ion-label>
                  <ion-select interface="alert" cancel-text="Annuler" placeholder="Veuillez choisir"
                              v-model="day.increase">
                              <ion-select-option v-for="n in 31" :key="n-1" :value="n-1">{{ n-1 }}€</ion-select-option>
                  </ion-select>
                </ion-item>
                </div>
              <hr/>
              <ion-text type="dark" class="label ion-margin-top checkboxes-container" style="margin-top: 15px;margin-bottom: 15px;">
                <h3>
                Choisissez votre offre :
                </h3>
              </ion-text>
              <ion-list>
                <ion-radio-group :value="day.pricing" style="display: flex;justify-content:space-around;" @ionChange="updatePricing($event, index)">
                  <ion-item  v-for="(item, index) in 3" :key="index" class="card-content-wrapper">
                    <label class="radio-card">
                      <ion-radio :value="index" ></ion-radio>

                        <!-- <span class="check-icon"></span> -->
                        <div class="card-content">
                          <h3 :class="['ion-no-padding', 'ion-margin', 'pricing-' + index]" id="pricing">{{ pricingToString[index] }}</h3>
                          <ul class="w-100 ion-no-padding">
                          <li
                            v-for="advantage in pricingToAdvantages[index]"
                            :key="advantage"
                            style="list-style-type: none"
                            :class="['w-100', 'pricing-' + index]"
                          >
                          • {{ advantage }}
                          </li>
                        </ul>
                        </div>

                    </label>
                   </ion-item>
                  </ion-radio-group>
                </ion-list>
              <ion-text color="dark">
                <p style="margin: 20px 0 10px 0">Choisissez vos intérimaires</p>
              </ion-text>
              <div class="typologie-container">
                <ion-item>
                  <ion-label class="label" position="floating">Pharmacien ({{displayPricing("pharmacist",day.pricing)}}€/h)</ion-label>
                  <ion-input class="typologie-input" type="number" v-model="day.pharmacienSubstitute"
                             :value="day.pharmacienSubstitute" min="0"/>
                </ion-item>
                <ion-item>
                  <ion-label class="label" position="floating">Préparateur ({{displayPricing("technician",day.pricing)}}€/h)</ion-label>
                  <ion-input class="typologie-input" type="number" v-model="day.preparers" :value="day.preparers"
                             min="0"/>
                </ion-item>
              </div>
              <div class="typologie-container">
                <ion-item>
                  <ion-label class="label" position="floating">Étudiant en pharmacie ({{displayPricing("student",day.pricing)}}€/h)</ion-label>
                  <ion-input class="typologie-input" type="number" v-model="day.students" :value="day.students"
                             min="0"/>
                </ion-item>
                <ion-item>
                  <ion-label class="label" position="floating">Tous profil (entre {{displayPricing("student",day.pricing)}}€/h et {{displayPricing("pharmacist",day.pricing)}}€/h)</ion-label>
                  <ion-input class="typologie-input" type="number" v-model="day.pharmacienPreparersStudentsSubstitute"
                             :value="day.pharmacienPreparersStudentsSubstitute" min="0"/>
                </ion-item>
              </div>
              <div class="typologie-container">
                <ion-item>
                  <ion-label class="label" position="floating">Préparateur ou Pharmacien (entre {{displayPricing("technician",day.pricing)}}€ et {{displayPricing("pharmacist",day.pricing)}}€/h)</ion-label>
                  <ion-input class="typologie-input" type="number" v-model="day.pharmacienPreparerSubstitute"
                             :value="day.pharmacienPreparerSubstitute" min="0"/>
                </ion-item>
                <ion-item>
                  <ion-label class="label" position="floating">Préparateur ou Étudiant (entre {{displayPricing("student",day.pricing)}}€ et {{displayPricing("technician",day.pricing)}}€/h)</ion-label>
                  <ion-input class="typologie-input" type="number" v-model="day.preparersStudents"
                             :value="day.preparersStudents" min="0"/>
                </ion-item>
            </div>

            </ion-card-content>
          </ion-card>
          </div>
          <div v-else>
            <ion-text color="secondary">
            <h1 class="ion-no-padding ion-margin">Veuillez sélectionner les jours de vos commandes récurrentes</h1>
            </ion-text>
            <ion-item slot="start" lines="full" class="input-wrapper display-content">
                  <ion-label class="label" position="floating">Date de début</ion-label>
                  <ion-datetime
                      presentation="date"
                      displayFormat="DD/MM"
                      locale='fr-FR'
                      show-default-buttons="true"
                      size="cover"
                      done-text="Confirmer"
                      cancel-text="Retour"
                      v-model="this.recurentMode.startDate"
                      @ionChange="this.updateEndTime"
                  />
                </ion-item>
                <ion-item slot="start" lines="full" class="input-wrapper display-content">
                  <ion-label class="label" position="floating">Date de fin</ion-label>
                  <ion-datetime
                      presentation="date"
                      locale='fr-FR'
                      displayFormat="DD/MM"
                      show-default-buttons="true"
                      size="cover"
                      done-text="Confirmer"
                      cancel-text="Retour"
                      v-model="this.recurentMode.endDate"
                      @ionChange="this.checkRecurrentInterval"

                  />
                </ion-item>
            <ion-list>
            <div v-if="this.recurentMode.canDisplayCheckBoxes">
              <ion-item v-for="day in recurentMode.recurentDayOption" :key="day.value">
                <ion-label>Tous les {{ day.label }}s</ion-label>
                <ion-checkbox slot="start" v-model="recurentMode.selectedOptions[day.value]" :value="day.value" @ionChange="generateDatesBetweenOnChange"></ion-checkbox>
              </ion-item>
              <div v-for="(dayChecked, index) in recurentMode.selectedOptions" :key="dayChecked.value">
                <AddMissionCard
                    v-if="dayChecked"
                    :title="`Tous les ${recurentMode.recurentDayOption[index].label}s`"
                    :day=recurentMode.recurentDayOption[index].dayObject
                    :isSubscriber=this.isSubscriber
                    :dayNumber=recurentMode.recurentDayOption[index].value
                    :updateRecurrentDays=this.updateRecurrentDays>
                  </AddMissionCard>
              </div>


            </div>
            </ion-list>
            <ion-text color="primary">
              <h3>{{ msgError }}</h3>
            </ion-text>
          </div>

          <ion-card v-if="canShowGeneralInformation" class="maxWidth">
            <ion-card-header>
              <ion-card-title>
                <h3>Informations générales</h3>
              </ion-card-title>
            </ion-card-header>
            <ion-card-content class="no-padding-mobile">
              <ion-list>
                <ion-text color="dark">
                  <p class="yes-no-label">
                    Est-ce que l'intérimaire doit savoir faire des vaccins ?
                  </p>
                </ion-text>
                <ion-radio-group :value="mission.hasVaccin" v-model="mission.hasVaccin">
                  <div style="width: 100%">
                    <ion-item>
                      <ion-radio :value="true" mode="md" slot="end"></ion-radio>
                      <ion-label>Oui</ion-label>
                    </ion-item>
                    <ion-item>
                      <ion-radio :value="false" mode="md" slot="end"></ion-radio>
                      <ion-label>Non</ion-label>
                    </ion-item>
                  </div>
                </ion-radio-group>
              </ion-list>
              <ion-list>
                <ion-text color="dark">
                  <p class="yes-no-label">
                    Est-ce que l'intérimaire doit savoir faire des tests COVID ?
                  </p>
                </ion-text>
                <ion-radio-group :value="mission.hasCovidTest" v-model="mission.hasCovidTest">
                  <div style="width: 100%">
                    <ion-item>
                      <ion-radio :value="true" mode="md" slot="end"></ion-radio>
                      <ion-label>Oui</ion-label>
                    </ion-item>
                    <ion-item>
                      <ion-radio :value="false" mode="md" slot="end"></ion-radio>
                      <ion-label>Non</ion-label>
                    </ion-item>
                  </div>
                </ion-radio-group>
              </ion-list>
            </ion-card-content>
          </ion-card>
          <ion-button class="validate" color="success" v-if="canValidate" v-on:click="save()">
          VALIDER
          </ion-button>
        </div>
        <div v-if="!isPharmacyComplete">
          <GoToInfoPharmacy/>
          <ion-button class="validate" color="success" v-if="canValidate" v-on:click="save()">
            VALIDER
          </ion-button>
        </div>
        <div v-if="!isIBANUploaded" class="ion-text-center ion-margin-top">
          <ion-text>Veuillez télécharger votre IBAN pour commencer à poster des annonces</ion-text>
        </div>

      </div>
      <div v-if="!isDesktop" class="footerBackground">
        <img class="ion-margin-bottom logoFooter" src="../../public/assets/icon/alloopharma-logo.png" alt="footer">
      </div>
      <div v-else class="footerNotMobile">
        <img class="ion-margin-bottom logoFooter" src="../../public/assets/icon/alloopharma-logo.png" alt="footer">
      </div>

    </ion-content>
  </ion-page>
</template>

<script>
import {
  alertController,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/vue';
import {Calendar} from 'v-calendar';
import moment from 'moment';
import store from '@/store'
import {trashOutline,} from 'ionicons/icons';
import AddMissionCard from "@/components/AddMissionCard";
import GoToInfoPharmacy from "@/components/GoToInfoPharmacy";
import {createMission} from "@/services/api";
import router from "@/router";
import {pricingToAdvantages, pricingToString, pricingToAmount, subscriberPricingToAmount}  from "@/services/utils";


export default {
  name: 'Availabilities',
  components: {
    Calendar, IonPage, IonContent, IonText, IonRadio, IonRadioGroup, IonIcon, IonCheckbox, IonItem,
    IonDatetime, IonLabel, IonList, GoToInfoPharmacy, IonInput,
    IonCardTitle, IonCardHeader, IonButton, IonCard, IonCardContent, IonSelect, IonSelectOption,
    AddMissionCard
  },
  mounted() {
    this.initRecurentDayObjects(this.recurentMode);
  },
  data() {
    return {
      isMsgValidation: false,
      isRecurent : null,
      msgError: "",
      initDays: [],
      days: [],
      mission: {
        hasCovidTest: null,
        hasVaccin: null,
      },
      weeks: [],
      recurentMode : {
        canDisplayCheckBoxes : false,
        startDate: null,
        endDate: null,
        recurentDayOption : [
          { label: 'dimanche', value: 0, dayObject: {} },
          { label: 'lundi', value: 1, dayObject: {} },
          { label: 'mardi', value: 2, dayObject: {}  },
          { label: 'mercredi', value: 3, dayObject: {}  },
          { label: 'jeudi', value: 4, dayObject: {}  },
          { label: 'vendredi', value: 5, dayObject: {}  },
          { label: 'samedi', value: 6, dayObject: {}  },
        ],
        selectedOptions : [false,false,false,false,false,false,false],

      }

    };
  },
  setup() {
    return {
      trashOutline,
      pricingToAdvantages,
      pricingToString,
      pricingToAmount,
      subscriberPricingToAmount,
    };
  },
  computed: {

    canShowGeneralInformation() {
      return this.days.length > 0 &&
          this.days.every((day) => {
            return [day.pharmacienSubstitute, day.preparers, day.students, day.preparersStudents, day.pharmacienPreparerSubstitute, day.pharmacienPreparersStudentsSubstitute].some((v) => v > 0)
          })
    },
    canValidate() {
      return ![this.mission.hasCovidTest, this.mission.hasVaccin].includes(null)
    },
    isPharmacyComplete() {
      return store.getters.isPharmacyComplete;
    },
    isSubscriber() {
      console.log(store.getters.user.pharmacy)
      return store.getters.user.pharmacy.isSubscriber;
    },
    isIBANUploaded() {
      console.log(store.getters.user.IBAN.url)
      return store.getters.user.IBAN.url != null;
    },
    isDesktop() {
      return store.getters.isDesktop
    },
    attributes() {
      return this.days.map(day => ({
        highlight: {
          color: "red",
          fillMode: "solid"
        },
        dates: day.id,
      }));
    },
  },

  methods: {
      generateDatesBetweenOnChange() {
        this.generatedDates = this.generateDatesBetween(this.recurentMode.startDate, this.recurentMode.endDate, this.recurentMode.selectedOptions);
        console.log(this.generatedDates)
        this.days = []
        this.generatedDates.forEach(date => {
          this.addDay(date);
          this.days.push(date);
        });
        console.log(this.dates)
      },
      generateDatesBetween(startDate, endDate, daysOfWeek) {

      // Convertir les dates en objets Date
      const start = new Date(startDate);
      const end = new Date(endDate);

      console.log(start)
      console.log(end)

      // Initialiser un tableau pour stocker les dates
      const dates = [];

      // Parcourir les dates entre start et end
      for (let current = new Date(start); current <= end; current.setDate(current.getDate() + 1)) {
        // Obtenir le jour de la semaine (0 = dimanche, 1 = lundi, ..., 6 = samedi)
        const dayOfWeekIndex = current.getDay();

        // Vérifier si le jour de la semaine est coché
        if (daysOfWeek[dayOfWeekIndex]) {
          const date = new Date(current);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');

          const formattedDate = `${year}-${month}-${day}`;
          dates.push({date : date, day : formattedDate});

        }
      }
      return dates
    },
    checkRecurrentInterval() {
      // Parse the beginTime and endTime to Date objects
      const beginDate = new Date(this.recurentMode.startDate);
      const endDate = new Date(this.recurentMode.endDate);

      // Calculate the difference in milliseconds
      const diff = endDate - beginDate;

      // Convert the difference to days
      const diffDays = diff / (1000 * 60 * 60 * 24);

      // Check if the interval is at least one week
      if (diffDays >= 7) {
        this.recurentMode.canDisplayCheckBoxes = true
      } else {
        this.recurentMode.canDisplayCheckBoxes = false
      }
    },
    initRecurentDayObjects(recurrentObject) {
      recurrentObject.recurentDayOption.forEach(item => {
        this.addDay(item.dayObject);
      });
      console.log(recurrentObject)
    },
    save() {
      const shifts = this.days.map((day) => {
        const {
          id,
          beginTime,
          endTime,
          pauseHours,
          isFlexible,
          pricing,
          increase,
          quantity,
          ownerStatus,
          pharmacienSubstitute,
          preparers,
          students,
          preparersStudents,
          pharmacienPreparersStudentsSubstitute,
          pharmacienPreparerSubstitute,
        } = day;
        const grades = [
          ...Array(parseInt(pharmacienSubstitute)).fill('pharmacien_substitute'),
          ...Array(parseInt(preparers)).fill('preparer'),
          ...Array(parseInt(students)).fill('student'),
          ...Array(parseInt(preparersStudents)).fill('preparer_student'),
          ...Array(parseInt(pharmacienPreparerSubstitute)).fill('pharmacien_preparer_substitute'),
          ...Array(parseInt(pharmacienPreparersStudentsSubstitute)).fill('pharmacien_preparer_student_substitute')
        ];
        return {
          day: id,
          beginTime,
          endTime,
          pauseHours,
          isFlexible,
          increase,
          quantity,
          grades,
          pricing,
          ownerStatus: ownerStatus ? 'present' : 'replacement'
        };
      })
      const data = {...this.mission, shifts};
      createMission(data).then(() => {
        this.days = [];
        router.push("/tableau-bord");
        this.showConfirmationAlert();
      })

    },
    displayPricing(candidateType, pricingIndex){
      if(this.isSubscriber)
        return subscriberPricingToAmount[candidateType][pricingIndex]
      return pricingToAmount[candidateType][pricingIndex]
    },
    updateEndTime() {
      // Parse the beginTime to a Date object
      const beginDate = new Date(this.recurentMode.startDate);
      // Add one month to the beginDate
      const endDate = new Date(beginDate.setMonth(beginDate.getMonth() + 1));
      // Format the endDate to the desired format (HH:mm)
      const formattedEndDate = endDate.toISOString().slice(0, 16); // Adjust the slice to match the v-model binding format

      // Set the endTime to the formattedEndDate
      this.recurentMode.endDate = formattedEndDate;
      this.recurentMode.canDisplayCheckBoxes = true
    },
    updatePricing(event, day) {
        // console.log('Current value:', JSON.stringify(event.detail.value));
        this.days[day].pricing = event.detail.value
        // day.pricing = event.detail.value;
        // console.log(day.pricing)
    },
    updateRecurrentDays(dayObject,dayNumber){

      this.days = this.days.map(dateObj => {
        // console.log(dateObj)
        if (dateObj.date.getDay() === dayNumber) {
          return {id: dateObj.day, ...dayObject, date: dateObj.date, day: dateObj.day};
        }

        return dateObj;
      });
      console.log(this.days)
    },
    async showConfirmationAlert() {
      const alert = await alertController.create({
        header: "Votre demande est bien enregistrée ✅",
        message: "Nous recherchons activement votre intérimaire.<br/>" +
            "Un expert en recrutement vous tiendra informé du statut de votre demande.",
        buttons: ['Parfait'],
      });

      await alert.present();
    },
    setIsRecurent(value) {
      this.isRecurent = value;
      if(!this.isRecurent){
        this.recurentMode.selectedOptions = [false,false,false,false,false,false,false]
        this.days = []
      }
      console.log(this.isRecurent)
    },

    addDay(day) {
      day.beginTime = '09:00';
      day.endTime = '18:00';
      day.pauseHours = '01:00';
      day.isFlexible = false;
      day.ownerStatus = false;
      day.increase = '0';
      day.pharmacienSubstitute = 0;
      day.preparers = 0;
      day.students = 0;
      day.preparersStudents = 0;
      day.pharmacienPreparerSubstitute = 0;
      day.pharmacienPreparersStudentsSubstitute = 0;
      day.pricing = 2;

    },
    onDayClick(day) {
      console.log(day)
      if (!moment(day.id).isAfter(moment().format('YYYY-MM-DD'))) {
        return
      }
      const dayEqual = (element) => element.id === day.id;
      const indexDayFinded = this.days.findIndex(dayEqual);
      if (indexDayFinded > -1) {
        this.days.splice(indexDayFinded, 1);
      } else {
        this.addDay(day);
        this.days.push(day);
      }
      console.log(this.days)
    },
    cancel() {
      this.days = [];
    }
  },
}
</script>

<style scoped>
.typologie-container > * {
  display: contents !important;
}

.typologie-container > *, .input-wrapper {
  width: 40%;
}

.typologie-input {
  align-items: flex-end !important;
}

.checkboxes-container {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0 0 12px;
}

.checkbox-input {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.display-content {
  display: contents !important;
}

@media screen and (max-device-width: 640px), screen and (max-width: 640px) {
  .checkboxes-container {
    flex-direction: column;
    margin: 0;
  }

  .checkbox-input {
    margin: 10px 0 10px 0;
  }

  .no-padding-mobile {
    padding: 10px 5px 10px 5px !important;
  }

  .typologie-container > *, .input-wrapper {
    width: 40%;
  }
}

.typologie-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.menuTitleFont {
  padding: 50px;
  margin-top: -20px
}

ion-button {
  margin-top: 20px
}

.validate {
  margin: 10px 0 30px 0;
}



ion-range {
  --bar-height: 8px;
  --knob-size: 40px;
}

#pricing {
  margin: 0 0 0 10px;
  font-weight: bold;
  font-size: 24px;
}


 .card-content-wrapper {
  background: #fff;
  border-radius: 5px;
  max-width: 280px;
  /* min-height: 130px; */
  padding-bottom: 0px;
  display: grid;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
  transition: 200ms linear;
}
 .check-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  transition: 200ms linear;
  position: relative;
}
.check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center center;
  transform: scale(1.6);
  transition: 200ms linear;
  opacity: 0;
}
 input[type=radio] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
 .card-content-wrapper {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #3057d5;
}

input[type=radio] + .card-content-wrapper {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #d1d1d1;
}


input[type=radio]:checked + .card-content-wrapper .check-icon {
  background: #3057d5;
  border-color: #3057d5;
  transform: scale(1.2);
}
 input[type=radio]:checked + .card-content-wrapper .check-icon:before {
  transform: scale(1);
  opacity: 1;
}
input[type=radio]:focus + .card-content-wrapper .check-icon {
  box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
  border-color: #3056d5;
}
.card-content img {
  margin-bottom: 10px;
}
 .card-content h4 {
  font-size: 16px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #1f2949;
  margin-bottom: 10px;
}
 .card-content h5 {
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: #686d73;
}


</style>
